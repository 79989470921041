import * as React from 'react';
import { Card, Phonon } from '../../../shared-types';
import PhononClient from '../clients/PhononClient';
import config from '../config';
import _cards from '../data/cards.json';

export type UserCard = Card & {
	phononKeyIndex: number;
};

const cards = _cards as Card[];

type PhonedexStore = {
	sessions: string[] | undefined;
	isSelectedSessionUnlocked: boolean;
	errorFetchingSessions: boolean;
	usersCards: UserCard[] | undefined;
	selectedSession: string | undefined;
	isConnectedToPairingServer: boolean;
	counterparty: string | undefined;
	fetchSessions: () => Promise<void>;
	selectSession: (session: string) => void;
	unlockSession: (pin: string) => Promise<void>;
	fetchPhonons: () => Promise<void>;
	connectToPairingServer: () => Promise<void>;
	connectToCounterParty: (counterPartySession: string) => Promise<void>;
	sendPhonon: (keyIndex: number) => Promise<void>;
};

const PhonedexStoreContext = React.createContext<PhonedexStore | undefined>(
	undefined,
);

const phononClient = new PhononClient(config.phononClientUrl);

export const PhonedexStoreProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const [sessions, setSessions] = React.useState<string[]>();
	const [errorFetchingSessions, setErrorFetchingSessions] =
		React.useState<boolean>(true);
	const [selectedSession, setSelectedSession] = React.useState<string>();
	const [isSelectedSessionUnlocked, setIsSelectedSessionUnlocked] =
		React.useState<boolean>(false);
	const [phonons, setPhonons] = React.useState<Phonon[]>();
	const [isConnectedToPairingServer, setIsConnectedToPairingServer] =
		React.useState<boolean>(false);
	const [counterparty, setCounterparty] = React.useState<string>();

	const fetchSessions = React.useCallback(async () => {
		setErrorFetchingSessions(false);
		try {
			const sessions = await phononClient.fetchSessions();
			setSessions(sessions);
			if (sessions.length === 1) {
				setSelectedSession(sessions[0]);
			}
		} catch (e) {
			console.error(e);
			setErrorFetchingSessions(true);
			throw e;
		}
	}, []);

	const unlockSession = React.useCallback(
		async (pin: string) => {
			if (!selectedSession) {
				throw new Error('No session selected');
			}

			await phononClient.selectSession(selectedSession, pin);
			setIsSelectedSessionUnlocked(true);
		},
		[selectedSession],
	);

	const fetchPhonons = React.useCallback(async () => {
		if (!selectedSession) {
			throw new Error('No session selected');
		}

		try {
			const newPhonons = await phononClient.fetchPhonons(selectedSession);
			setPhonons(newPhonons);
		} catch (e) {
			console.error(e);
			setSelectedSession(undefined);
			setIsSelectedSessionUnlocked(false);
			setPhonons(undefined);
			throw e;
		}
	}, [selectedSession]);

	const connectToPairingServer = React.useCallback(async () => {
		try {
			if (!selectedSession) {
				throw new Error('No session selected');
			}
			await phononClient.connectToPairingServer(
				selectedSession,
				config.phononPairingServerUrl,
			);
			setIsConnectedToPairingServer(true);
			console.log('Connected to pairing server');
		} catch (e) {
			console.error(e);
			setIsConnectedToPairingServer(false);
			throw e;
		}
	}, [selectedSession]);

	const connectToCounterParty = React.useCallback(
		async (newCounterparty: string) => {
			try {
				if (!selectedSession) {
					throw new Error('No session selected');
				}

				await phononClient.pair(selectedSession, newCounterparty);
				setCounterparty(newCounterparty);
			} catch (e) {
				console.error(e);
				setCounterparty(undefined);
				throw e;
			}
		},
		[selectedSession],
	);

	const sendPhonon = React.useCallback(
		async (keyIndex: number) => {
			if (!selectedSession) {
				throw new Error('No session selected');
			}
			await phononClient.send(selectedSession, keyIndex);
		},
		[selectedSession],
	);

	React.useEffect(() => {
		if (!selectedSession || !isSelectedSessionUnlocked) {
			return;
		}

		fetchPhonons();
	}, [selectedSession, isSelectedSessionUnlocked, fetchPhonons]);

	const usersCards: UserCard[] | undefined = React.useMemo(() => {
		if (!phonons) {
			return undefined;
		}

		const _usersCards = [];

		for (const phonon of phonons) {
			const card = cards.find((card) => card.publicKey === phonon.PubKey);

			if (!card) {
				continue;
			}

			_usersCards.push({
				...card,
				phononKeyIndex: phonon.KeyIndex,
			});
		}

		return _usersCards.sort((a, b) => a.cardNumber - b.cardNumber);
	}, [phonons]);

	const value = React.useMemo(
		() => ({
			sessions,
			isSelectedSessionUnlocked,
			errorFetchingSessions,
			usersCards,
			selectedSession,
			isConnectedToPairingServer,
			counterparty,
			fetchSessions,
			unlockSession,
			selectSession: setSelectedSession,
			fetchPhonons,
			connectToPairingServer,
			connectToCounterParty,
			sendPhonon,
		}),
		[
			sessions,
			isSelectedSessionUnlocked,
			errorFetchingSessions,
			usersCards,
			selectedSession,
			isConnectedToPairingServer,
			counterparty,
			fetchSessions,
			unlockSession,
			setSelectedSession,
			fetchPhonons,
			connectToPairingServer,
			connectToCounterParty,
			sendPhonon,
		],
	);

	return (
		<PhonedexStoreContext.Provider value={value}>
			{children}
		</PhonedexStoreContext.Provider>
	);
};

export const usePhonedexStore = () => {
	const context = React.useContext(PhonedexStoreContext);

	if (context === undefined) {
		throw new Error(
			'usePhonedexStore must be used within a PhonedexStoreProvider',
		);
	}
	return context;
};
