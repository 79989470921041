import * as React from 'react';
import { Card } from '../../../shared-types';
import CardList from '../components/CardList';
import _cards from '../data/cards.json';

const cards = _cards as Card[];

const Cards: React.FC = () => {
	return (
		<div>
			<h1>CARDS</h1>
			<CardList cards={cards} />
		</div>
	);
};

export default Cards;
