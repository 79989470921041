import * as React from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../../shared-types';
import { truncateAddress } from '../utils/format';

type Props = {
	cards: Card[];
	showTransferLink?: boolean;
};

const CardList: React.FC<Props> = (props) => {
	return (
		<table className='table table-compact'>
			<thead>
				<tr>
					<th>#</th>
					<th>Name</th>
					<th>Type</th>
					<th className='text-center'>Rarity</th>
					<th className='text-right'>Phonon Public Key</th>
					{props.showTransferLink && <th></th>}
				</tr>
			</thead>
			<tbody>
				{props.cards.map((card) => {
					const numberOfSamePokemon = props.cards.filter(
						(c) => c.name === card.name,
					).length;

					return (
						<tr key={card.cardNumber}>
							<th>{card.cardNumber}</th>
							<td>{card.name}</td>
							<td>{card.type}</td>
							<td className='text-center'>1/{numberOfSamePokemon}</td>
							<td className='text-right'>
								{truncateAddress(card.publicKey, 10, 10)}
							</td>
							{props.showTransferLink && (
								<td className='text-right'>
									<Link to={`/phonedex/transfer?card=${card.cardNumber}`}>
										Transfer
									</Link>
								</td>
							)}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default CardList;
