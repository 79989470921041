import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './navigation/Home';
import Cards from './navigation/Cards';
import Packs from './navigation/Packs';
import Phonedex from './navigation/Phonedex';
import Transfer from './navigation/Transfer';
import { PhonedexStoreProvider } from './stores/PhonedexStore';

const App = () => {
	return (
		<div className='App prose container mx-auto p-5 sm:px-0'>
			<PhonedexStoreProvider>
				<BrowserRouter>
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='/cards' element={<Cards />} />
						<Route path='/packs' element={<Packs />} />
						<Route path='/phonedex' element={<Phonedex />} />
						<Route path='/phonedex/transfer' element={<Transfer />} />
					</Routes>
				</BrowserRouter>
			</PhonedexStoreProvider>
		</div>
	);
};

export default App;
