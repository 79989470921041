import * as React from 'react';
import { usePhonedexStore } from '../stores/PhonedexStore';

type Props = {
	children: React.ReactNode | React.ReactNode[];
};

const ConnectCardWrapper: React.FC<Props> = (props) => {
	const {
		sessions,
		isSelectedSessionUnlocked,
		errorFetchingSessions,
		selectedSession,
		unlockSession,
		selectSession,
		fetchSessions,
	} = usePhonedexStore();

	React.useEffect(() => {
		fetchSessions();
	}, [fetchSessions]);

	const content = (() => {
		if (!!errorFetchingSessions) {
			return <NoCardConnected />;
		}

		if (!isSelectedSessionUnlocked && sessions && sessions.length > 1) {
			return (
				<Sessions
					sessions={sessions}
					onSelectSession={(session: string) => selectSession(session)}
				/>
			);
		}

		if (!isSelectedSessionUnlocked && selectedSession) {
			return <Unlock onSubmit={unlockSession} />;
		}

		return props.children;
	})();

	return <div>{content}</div>;
};

export default ConnectCardWrapper;

const NoCardConnected: React.FC = () => {
	return (
		<div>
			<h3>No phonon card detected.</h3>
			<p>
				The phonedex requires the{' '}
				<a href='https://github.com/GridPlus/phonon-client'>phonon client</a> to
				be running locally at port 8080. If you have a card connected and
				running the client please clear the client cache by restarting.
			</p>
		</div>
	);
};

const Sessions: React.FC<{
	sessions: string[];
	onSelectSession: (session: string) => void;
}> = (props) => {
	return (
		<div>
			<h3>Select a card</h3>
			{props.sessions.map((session) => (
				<button
					className='btn btn-primary'
					key={session}
					onClick={() => props.onSelectSession(session)}
				>
					{session}
				</button>
			))}
		</div>
	);
};

const Unlock: React.FC<{
	onSubmit: (pin: string) => Promise<void>;
}> = (props) => {
	const [pin, setPin] = React.useState('');

	return (
		<div>
			<h3>Unlock</h3>
			<input
				type='text'
				value={pin}
				onChange={(e) => setPin(e.target.value)}
				placeholder='Pin'
				className='input input-bordered w-full'
			/>
			<button
				className='btn btn-primary w-full mt-5'
				onClick={() => props.onSubmit(pin)}
				disabled={pin.length === 0}
			>
				Unlock
			</button>
		</div>
	);
};
