import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePhonedexStore, UserCard } from '../stores/PhonedexStore';
import ConnectCardWrapper from '../components/ConnectCardWapper';
import Pair from '../components/Pair';
import classNames from 'classnames';
import config from '../config';

type Action = 'send' | 'receive';

const Transfer: React.FC = () => {
	const {
		usersCards,
		isSelectedSessionUnlocked,
		isConnectedToPairingServer,
		selectedSession,
		counterparty,
		connectToPairingServer,
		sendPhonon,
	} = usePhonedexStore();
	const [searchParams] = useSearchParams();

	const _cardNumber = searchParams.get('card')
		? Number(searchParams.get('card'))
		: usersCards?.[0].cardNumber;
	const trainerId = searchParams.get('trainerId');

	const [cardNumber, setCardNumber] = React.useState<number | undefined>(
		_cardNumber,
	);
	const [tab, setTab] = React.useState<Action>(trainerId ? 'receive' : 'send');
	const [sending, setSending] = React.useState<boolean>(false);
	const [sent, setSent] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (
			isSelectedSessionUnlocked &&
			// cant connect to pairing server when phonons are being fetched
			usersCards &&
			!isConnectedToPairingServer
		) {
			connectToPairingServer();
		}
	}, [
		isSelectedSessionUnlocked,
		isConnectedToPairingServer,
		usersCards,
		connectToPairingServer,
	]);

	const onSelectCard = React.useCallback((newCardNumber: number) => {
		setCardNumber(newCardNumber);
		setSent(false);
	}, []);

	const onSendPhonemon = React.useCallback(async () => {
		setSending(true);
		try {
			await sendPhonon(cardNumber!);
			setSent(true);
		} finally {
			setSending(false);
		}
	}, [cardNumber, sendPhonon]);

	const loading = !usersCards || !isConnectedToPairingServer;

	return (
		<div>
			<h1>Transfer</h1>
			<p>Your trainer id: {selectedSession}</p>
			<ConnectCardWrapper>
				<div className='tabs'>
					<span
						className={classNames('tab tab-bordered', {
							'tab-active': tab === 'send',
						})}
						onClick={() => setTab('send')}
					>
						Send
					</span>
					<span
						className={classNames('tab tab-bordered', {
							'tab-active': tab === 'receive',
						})}
						onClick={() => setTab('receive')}
					>
						Receive
					</span>
				</div>
				<div className='mt-5'>
					{loading && <p>loading...</p>}
					{tab === 'send' && !loading && (
						<Send
							userCards={usersCards}
							cardNumber={cardNumber}
							selectedSession={selectedSession}
							counterparty={counterparty}
							sending={sending}
							sent={sent}
							onSelectCard={onSelectCard}
							onSendPhonemon={onSendPhonemon}
						/>
					)}
					{tab === 'receive' && !loading && (
						<Receive loading={loading} counterparty={counterparty} />
					)}
					{!counterparty && !loading && <Pair />}
				</div>
			</ConnectCardWrapper>
		</div>
	);
};

export default Transfer;

const Send: React.FC<{
	selectedSession: string | undefined;
	counterparty: string | undefined;
	userCards: UserCard[] | undefined;
	cardNumber: number | undefined;
	sending: boolean;
	sent: boolean;
	onSelectCard: (cardNumber: number) => void;
	onSendPhonemon: () => Promise<void>;
}> = (props) => {
	const {
		userCards,
		cardNumber,
		counterparty,
		selectedSession,
		sending,
		sent,
		onSelectCard,
		onSendPhonemon,
	} = props;
	const domain = `${config.domain}/phonedex/transfer?trainerId=${selectedSession}`;

	if (userCards?.length === 0) {
		return <p>You have no phonemon</p>;
	}

	if (counterparty && !!userCards?.length) {
		return (
			<div>
				<ConnectedWith counterparty={counterparty} />
				<label className='label'>
					<span className='label-text'>Select a phonemon to send</span>
				</label>
				<select
					className='select select-bordered w-full'
					value={cardNumber}
					onChange={(e) => onSelectCard(Number(e.target.value))}
					disabled={sending}
				>
					{userCards.map((card) => (
						<option key={card.cardNumber} value={card.cardNumber}>
							{card.cardNumber} - {card.name}
						</option>
					))}
				</select>
				<button
					className={classNames('btn btn-primary w-full mt-5', {
						loading: sending,
					})}
					disabled={!cardNumber}
					onClick={onSendPhonemon}
				>
					Send
				</button>
				{sent && <p>Sent!</p>}
			</div>
		);
	}

	return (
		<div>
			<ol>
				<li>
					Get your fellow trainer to visit{' '}
					<a href={domain} className='italic'>
						{domain}
					</a>
					. Once connected they will share their trainer id with you.{' '}
				</li>
				<li>
					Once you have their trainer id enter it below. Both you and your
					fellow trainer must connect within 3 seconds of each other.
				</li>
			</ol>
		</div>
	);
};

const Receive: React.FC<{
	loading: boolean;
	counterparty: string | undefined;
}> = (props) => {
	if (props.counterparty) {
		return (
			<div>
				<ConnectedWith counterparty={props.counterparty} />
			</div>
		);
	}

	return (
		<div>
			<ol>
				<li>Share your trainer id above with your fellow trainer.</li>
				<li>
					When they are ready click the button below. Both you and your fellow
					trainer must connect within 3 seconds of each other.
				</li>
			</ol>
		</div>
	);
};

const ConnectedWith: React.FC<{ counterparty: string }> = (props) => {
	return (
		<div>
			<p>Connected with trainer {props.counterparty}</p>
		</div>
	);
};
