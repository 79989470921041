import classNames from 'classnames';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePhonedexStore } from '../stores/PhonedexStore';

const Pair: React.FC = () => {
	const [searchParams] = useSearchParams();
	const { connectToCounterParty } = usePhonedexStore();
	const trainerId = searchParams.get('trainerId');
	const [error, setError] = React.useState<boolean>(false);

	const [counterParty, setCounterParty] = React.useState<string>(
		trainerId || '',
	);

	const [connectingWithCounterParty, setConnectingWithCounterParty] =
		React.useState<boolean>(false);

	const onConnectToCounterParty = React.useCallback(async () => {
		setConnectingWithCounterParty(true);

		try {
			await connectToCounterParty(counterParty);
		} catch (e) {
			setError(true);
		} finally {
			setConnectingWithCounterParty(false);
		}
	}, [counterParty, connectToCounterParty]);

	return (
		<div className='mt-5'>
			<input
				type='text'
				value={counterParty}
				onChange={(e) => {
					setCounterParty(e.target.value);
					setError(false);
				}}
				placeholder="Fellow trainer's ID"
				className='input input-bordered w-full'
			/>
			<button
				className={classNames('btn btn-primary w-full mt-5', {
					loading: connectingWithCounterParty,
				})}
				disabled={counterParty.length === 0}
				onClick={onConnectToCounterParty}
			>
				Connect with Trainer
			</button>
			{error && <p>Failed to connect. Try again.</p>}
		</div>
	);
};

export default Pair;
