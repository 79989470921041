import axios from 'axios';
import { Phonon } from '../../../shared-types';

export default class PhononClient {
	constructor(private baseUrl: string) {}

	public fetchSessions = async (): Promise<string[]> => {
		const sessionsResponse = await axios.get<{ Sessions: string[] }>(
			`${this.baseUrl}/listSessions`,
		);
		return sessionsResponse.data.Sessions;
	};

	public selectSession = async (
		session: string,
		pin: string,
	): Promise<void> => {
		await axios.post(`${this.baseUrl}/cards/${session}/unlock`, {
			pin,
		});
	};

	public fetchPhonons = async (session: string): Promise<Phonon[]> => {
		const response = await axios.post(
			`${this.baseUrl}/cards/${session}/listPhonons`,
		);

		return response.data === null ? [] : response.data;
	};

	public connectToPairingServer = async (
		session: string,
		url: string,
	): Promise<void> => {
		await axios.post(`${this.baseUrl}/cards/${session}/connect`, { url });
	};

	public pair = async (
		session: string,
		counterPartySessionId: string,
	): Promise<void> => {
		await axios.post(`${this.baseUrl}/cards/${session}/pair`, {
			cardId: counterPartySessionId,
		});
	};

	public send = async (
		session: string,
		phononKeyIndex: number,
	): Promise<void> => {
		await axios.post(
			`${this.baseUrl}/cards/${session}/phonon/${phononKeyIndex}/send`,
		);
	};
}
