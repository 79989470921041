import * as React from 'react';
import { BoosterPack } from '../../../shared-types';
import _packs from '../data/booster-packs.json';

const packs = _packs as (BoosterPack & { sold?: boolean })[];

const Packs: React.FC = () => {
	return (
		<div>
			<h1>BOOSTER PACKS</h1>
			<p>
				Contact hinchy.eth in the PhononDAO discord to get your booster pack.
			</p>
			<table className='table table-compact'>
				<thead>
					<tr>
						<th>#</th>
						<th>Status</th>
						<th className='text-center'>Cards Hash</th>
					</tr>
				</thead>
				<tbody>
					{packs.map((pack) => {
						return (
							<tr key={pack.packNumber}>
								<th>{pack.packNumber}</th>
								<td>{pack.sold ? 'Sold' : 'Available'}</td>
								<td>{pack.hash}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default Packs;
