import * as React from 'react';
import { Link } from 'react-router-dom';
const Home: React.FC = () => {
	return (
		<div>
			<h1>PHONEMON</h1>
			<p>
				Phonemon is a proof of concept trading card collection built on top of
				the <a href='http://phonon.network'>Phonon Network</a>. The collection
				consists of 250 phonons each representing a unique collectable card
				based on the original Pokemon card set.
			</p>

			<ul>
				<li>
					{<Link to='/cards'>Cards List</Link>} - View all available phonemon
					and their associated phonon public keys. This data can be verified on{' '}
					<a href='https://gateway.pinata.cloud/ipfs/QmVXb95aasKfSf3ba9UaqNvKipWt4qHQodZNEGiMfy16XA'>
						IPFS
					</a>
					.
				</li>
				<li>
					{<Link to='/packs'>Booster Packs</Link>} - Check for available booster
					packs. Each pack contains 10 phonemon cards.
				</li>
				<li>
					{<Link to='/phonedex'>Phonedex</Link>} - Connect your Phonon card and
					admire your Phonemon collection.
				</li>
			</ul>
		</div>
	);
};

export default Home;
