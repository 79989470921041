import * as React from 'react';
import { Link } from 'react-router-dom';
import CardList from '../components/CardList';
import ConnectCardWrapper from '../components/ConnectCardWapper';
import { usePhonedexStore } from '../stores/PhonedexStore';

const Phonedex: React.FC = () => {
	const { usersCards, isSelectedSessionUnlocked } = usePhonedexStore();

	const showLoading = isSelectedSessionUnlocked && !usersCards;

	return (
		<div>
			<h1>PHONEDEX</h1>
			<ConnectCardWrapper>
				{showLoading && <span>loading...</span>}
				{usersCards && usersCards.length && (
					<CardList cards={usersCards} showTransferLink={true} />
				)}
				{usersCards && !usersCards.length && (
					<p>
						You don't have any phonemon. Checkout if there are any{' '}
						{<Link to='/packs'>booster packs</Link>} available or ask a phonemon
						trainer to send you one.
					</p>
				)}
			</ConnectCardWrapper>
		</div>
	);
};

export default Phonedex;
